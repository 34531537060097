export const navigationDb = [
    {
        id: 101,
        idMaster: null,
        idUp: null,
        idDown: 201,
        idLeft: 102,
        idRight: 105,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 102,
        idMaster: null,
        idUp: null,
        idDown: 202,
        idLeft: 103,
        idRight: 101,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 103,
        idMaster: null,
        idUp: null,
        idDown: 203,
        idLeft: 104,
        idRight: 102,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 104,
        idMaster: null,
        idUp: null,
        idDown: 204,
        idLeft: 108,
        idRight: 103,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 105,
        idMaster: null,
        idUp: null,
        idDown: 205,
        idLeft: 101,
        idRight: 106,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 106,
        idMaster: null,
        idUp: null,
        idDown: 206,
        idLeft: 105,
        idRight: 107,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 107,
        idMaster: null,
        idUp: null,
        idDown: 207,
        idLeft: 106,
        idRight: 108,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 108,
        idMaster: null,
        idUp: null,
        idDown: null,
        idLeft: 107,
        idRight: 104,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 201,
        idMaster: null,
        idUp: 101,
        idDown: null,
        idLeft: 202,
        idRight: 205,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 202,
        idMaster: null,
        idUp: 102,
        idDown: null,
        idLeft: 203,
        idRight: 201,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 203,
        idMaster: null,
        idUp: 103,
        idDown: null,
        idLeft: 204,
        idRight: 202,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 204,
        idMaster: null,
        idUp: 104,
        idDown: null,
        idLeft: 207,
        idRight: 203,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 205,
        idMaster: null,
        idUp: 105,
        idDown: null,
        idLeft: 201,
        idRight: 206,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 206,
        idMaster: null,
        idUp: 106,
        idDown: null,
        idLeft: 205,
        idRight: 207,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
    {
        id: 207,
        idMaster: null,
        idUp: 107,
        idDown: null,
        idLeft: 206,
        idRight: 204,
        idReserve1: null,
        idReserve2: null,
        idReserve3: null,
        p1: null,
        p2: null,
        del: null,
    },
];
export const textsDb = [
    {
        id: 101,
        name1: '',
        name2: 'ГОРОДСКОЙ ПОСЕЛОК',
        name3: '',
        fullName: '',
        slogan: 'ДРУЯ: ГОРОД НА КРАЮ ЗЕМЛИ',
        text: 'Друя находится на самом краю Беларуси – она стоит на левом берегу Западной Двины. А на правом уже виднеется Латвия, граница по реке появилась с первым разделом Речи Посполитой.',
        comment1: 'Друя находится на самом краю Беларуси – она стоит на левом берегу Западной Двины. А на правом уже виднеется Латвия. Деревня Пиедруя на противоположном берегу когда-то была частью поселения, граница по реке появилась с первым разделом Речи Посполитой. Современная Друя – это тихое местечко на пару тысяч жителей, но когда-то она была довольно видным торговым и ремесленным городком.',
        comment2: 'Впервые Друя упоминается в летописях в 1386 году как одно из владений князя Андрея Ольгердовича. Но некоторые историки считают, что поселение могло возникнуть гораздо раньше, еще во времена Полоцкого княжества. Друя стояла на важном торговом пути, поэтому жизнь здесь всегда кипела. В конце XV века Друя стала принадлежать князьям Масальским, которые, кроме самого местечка и прилегающих земель, получили еще и право свободной торговли на Двине.',
        comment3: 'В начале XVII века землями заинтересовался Лев Сапега. Он приобрел местную усадьбу, а заодно и несколько близлежащих деревень. Сапегам все здесь пришлось по душе, они даже решили основать неподалеку новый город в свою честь – так появился Сапежин (теперь это район Друи). В 1618 году Сапежин получил право на самоуправление и красивый герб – ладья с распущенным парусом на голубом барочном щите. К слову, современная Друя тоже гордо использует этот герб. Во времена Сапег здесь жили почти две тысячи человек, на 300 домов была почти сотня корчем, действовали католические, православные, униатские храмы и синагога.',
        comment4: 'В 1772 году задвинская часть Друи, которая тогда называлась Придруйск, оказалась в составе Российской империи, а уже в 1793-м – то есть после второго раздела Речи Посполитой – местечко снова объединилось. Так Россия заполучила его целиком.',
        comment5: 'Во время войны с Наполеоном в окрестностях случилось несколько важных боев, а во время Первой мировой Друя стала прифронтовым городом. Тогда здесь появилась узколейка для военных нужд, она соединяла Друю с Браславом и Опсой. В советское время по Западной Двине проходила граница между Беларусской и Латвийской ССР.',
        linkImg: '101.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=0&end=152',
        coordinates: {
            lat: 55.79072039312701,
            lng: 27.444802112550597,
        },
    },
    {
        id: 102,
        name1: '',
        name2: 'КОСТЕЛ И КАТОЛИЧЕСКИЙ МОНАСТЫРЬ',
        name3: '',
        fullName: '',
        slogan: 'КОСТЕЛ СВЯТОЙ ТРОИЦЫ',
        text: 'Высокий костел возвели на деньги Сапеги в 1643-1646 годах, после того как в местечке был основан монастырь бернардинцев. Довольно аскетичный снаружи (что характерно для раннего барокко), внутри он богато усыпан роскошным декором.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '102.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=433&end=736',
        coordinates: {
            lat: 55.788247340348875,
            lng: 27.46318568307679,
        },
    },
    {
        id: 103,
        name1: '',
        name2: 'ПРАВОСЛАВНЫЙ ХРАМ',
        name3: '',
        fullName: '',
        slogan: 'БЛАГОВЕЩЕНСКАЯ ЦЕРКОВЬ',
        text: 'Благовещенская церковь была главной православной святыней Друи, ее строили в XVIII-XIX веках. Судьба у нее довольно типичная для местного храма: в советское время здесь разместили склад, а потом вообще закрыли. Сейчас церковь восстанавливают: башню-колокольню уже отреставрировали.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '103.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=253&end=432',
        coordinates: {
            lat: 55.79182817304647,
            lng: 27.454160967734023,
        },
    },
    {
        id: 104,
        name1: '',
        name2: 'АРХЕОЛОГИЧЕСКИЕ ПАМЯТНИКИ',
        name3: '',
        fullName: '',
        slogan: 'БОРИСОВ КАМЕНЬ',
        text: 'В Беларуси существует группа уникальных памятников прошлого, так называемые Борисовы камни. Они представляют собой огромные (до нескольких метров) валуны с выбитыми на них крестами и различными надписями. Свое название эти камни получили от полоцкого князя Бориса, правившего в XII веке.',
        comment1: 'В Беларуси существует группа уникальных памятников прошлого, так называемые Борисовы камни. Они представляют собой огромные (до нескольких метров) валуны с выбитыми на них крестами и различными надписями. Свое название эти камни получили от полоцкого князя Бориса, правившего в XII веке, имя которого было выбито на большинстве из них. Правда, существовал один Рогволодов камень и еще сохранился безымянный валун известный как Воротишин крест, но все они относятся к тому же времени. Точная причина появления на наших землях столь необычных памятников до сих пор неизвестна.',
        comment2: 'Большинство из Борисовых камней было уничтожено в период борьбы с религией. Та же участь постигла и Рогволодов камень с надписью "Въ 6679 (1171) месяца мая въ 7 день доспенъ крест сей. Господи помози рабу своему Василію въ крещеніи именемъ Рогволоду сыну Борисову", стоявший у деревни Дятлово Оршанского района. Он был варварски взорван в 1930-х гг. и обломки его были использованы для строительства шоссе Москва—Минск.',
        comment3: 'Сохранилось всего четыре "белорусских" Борисовых камня, один из которых в 1888 г. был вывезен в Россию и сейчас находится в музее-заповеднике Коломенское. В Беларуси осталось три: возле храма св. Софии в Полоцке (Витебская обл.), Воротишин крест в д. Камено (Вилейский район, Минская обл.) и в г. Друя (Браславский район, Витебская обл.). Кстати, последний из них долгое время был скрыт под водой на глубине, куда его стянул весенний ледоход еще в первой половине XX века. Только в 2002 году его удалось обнаружить и вытащить на берег.',
        comment4: '',
        comment5: '',
        linkImg: '104.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=35&end=152',
        coordinates: {
            lat: 55.79173379586035,
            lng: 27.448967556091237,
        },
    },
    {
        id: 105,
        name1: '',
        name2: 'ЕВРЕЙСКОЕ НАСЛЕДИЕ БЕЛАРУСИ',
        name3: '',
        fullName: '',
        slogan: 'ЕВРЕЙСКОЕ КЛАДБИЩЕ И МЕМОРИАЛЫ',
        text: 'Во время Второй мировой войны в Друе было создано еврейское гетто, в котором было уничтожено больше двух тысяч человек. На еврейском кладбище, самом большом на территории Беларуси, есть захоронения и жертв холокоста, и могилы с многовековой историей.',
        comment1: 'Из истории мы знаем, что по территории Беларуси проходила черта оседлости, во многих городах и местечках евреи составляли большинство населения. Местечко считалось одним из трех кагалов на Браславщине — два других были в Браславе и Видзах. В конце XIX - начале ХХ веков еврейское население преобладало в большинстве здешних поселений.',
        comment2: 'В 1905 году евреями были 3,5 из 5,5 тыс. жителей Друи. Друйское еврейское кладбище — одно из самых больших и старых не только на Браславщине, но и в Беларуси. Первые захоронения появились здесь предположительно в 1542 году.',
        comment3: 'В начале Второй мировой войны в местечке было создано гетто — друйские евреи подверглись гонениям, затем многих из них расстреляли. Те, кому удалось избежать гибели, навсегда покинули здешние края. В мирное время по инициативе евреев-выходцев из Друи кладбище привели в порядок и окружили каменной оградой. Для Друи это не только место памяти, но и знаковая достопримечательность. Пройдясь по кладбищу, расположенному на пологих холмах среди высоких сосен, можно увидеть уникальные цветные надгробия, которые в Беларуси считаются редкостью.',
        comment4: '',
        comment5: '',
        linkImg: '105.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=95&end=152',
        coordinates: {
            lat: 55.78446957178158,
            lng: 27.42887652737348,
        },
    },
    {
        id: 106,
        name1: '',
        name2: 'ДОСТОПРИМЕЧАТЕЛЬНОСТИ',
        name3: '',
        fullName: '',
        slogan: 'УЗКОКОЛЕЙКА',
        text: 'На рубеже XIX-XX веков в Друе проживало порядка пяти с половиной тысяч человек, работало 275 ремесленников. В городе насчитывалось 359 домов, 52 лавки, 13 культовых строений. Каждый год в поселении проводилось 8 ярмарок. Когда-то из Друи в Дукштас шла узкоколейная дорога.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '106.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=99&end=152',
        coordinates: {
            lat: 55.791773,
            lng: 27.450289,
        },
    },
    {
        id: 107,
        name1: '',
        name2: 'ДОСТОПРИМЕЧАТЕЛЬНОСТИ',
        name3: '',
        fullName: '',
        slogan: 'ПОЛЬСКАЯ ШКОЛА',
        text: 'Двадцать лет XX века почти половина территории современной Беларуси находилась в составе другого государства. Гродненская, Брестская, часть Минской и часть Витебской областей по итогам Рижского мирного договора в 1921 году были присоединены ко Второй Речи Посполитой.',
        comment1: 'Двадцать лет XX века почти половина территории современной Беларуси находилась в составе другого государства. Гродненская, Брестская, часть Минской и часть Витебской областей по итогам Рижского мирного договора в 1921 году были присоединены ко Второй Речи Посполитой. ',
        comment2: 'С этого времени и до 1939 года Беларусь была разделена на западную и восточную части. Это разделение не могло не сказаться на архитектуре регионов, ведь на территории Беларуси в то время работали представители двух архитектурных школ двух государств, которые идеологически противостояли друг другу.',
        comment3: 'Западная Беларусь, или, как этот край назвали в Польше, Восточные кресы (kresy - окраины), в составе нового Польского государства считалась отсталым регионом. Тем не менее за два десятилетия поляки вложили в развитие "кресов" миллионы польских злотых. Были восстановлены разрушенные Первой мировой войной города, развивалась инфраструктура края, строились новые предприятия, увеличивалось присутствие польских Вооруженных сил. За эти годы было построено множество зданий разных стилей, большинство из которых сохранились до наших дней.',
        comment4: 'Польская школа в Друе - один из примеров таких типовых построек. Она принимала учеников не только в “польское время”, но еще в семидесятые годы прошлого столетия друевские мальчишки и девченки бегали на занятия по этой лестнице-аллее.',
        comment5: '',
        linkImg: '107.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=737&end=798',
        coordinates: {
            lat: 55.7901988,
            lng: 27.439232,
        },
    },
    {
        id: 108,
        name1: '',
        name2: 'ДОСТОПРИМЕЧАТЕЛЬНОСТИ',
        name3: '',
        fullName: '',
        slogan: 'ЧУДЕСА ДРУИ',
        text: 'Друя богата на чудеса. Вот этот персонаж стоит во дворе одного из жителей деревни Друя.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '108.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=0&end=152',
        coordinates: {
            lat: 55.790734,
            lng: 27.443896,
        },
    },
    {
        id: 201,
        name1: '',
        name2: 'ГОРОДСКОЙ ПОСЕЛОК',
        name3: '',
        fullName: '',
        slogan: 'ДРУЯ: ГОРОД НА КРАЮ ЗЕМЛИ',
        text: 'Друя находится на самом краю Беларуси – она стоит на левом берегу Западной Двины. А на правом уже виднеется Латвия, граница по реке появилась с первым разделом Речи Посполитой.',
        comment1: 'Друя находится на самом краю Беларуси – она стоит на левом берегу Западной Двины. А на правом уже виднеется Латвия. Деревня Пиедруя на противоположном берегу когда-то была частью поселения, граница по реке появилась с первым разделом Речи Посполитой. Современная Друя – это тихое местечко на пару тысяч жителей, но когда-то она была довольно видным торговым и ремесленным городком.',
        comment2: 'Впервые Друя упоминается в летописях в 1386 году как одно из владений князя Андрея Ольгердовича. Но некоторые историки считают, что поселение могло возникнуть гораздо раньше, еще во времена Полоцкого княжества. Друя стояла на важном торговом пути, поэтому жизнь здесь всегда кипела. В конце XV века Друя стала принадлежать князьям Масальским, которые, кроме самого местечка и прилегающих земель, получили еще и право свободной торговли на Двине.',
        comment3: 'В начале XVII века землями заинтересовался Лев Сапега. Он приобрел местную усадьбу, а заодно и несколько близлежащих деревень. Сапегам все здесь пришлось по душе, они даже решили основать неподалеку новый город в свою честь – так появился Сапежин (теперь это район Друи). В 1618 году Сапежин получил право на самоуправление и красивый герб – ладья с распущенным парусом на голубом барочном щите. К слову, современная Друя тоже гордо использует этот герб. Во времена Сапег здесь жили почти две тысячи человек, на 300 домов была почти сотня корчем, действовали католические, православные, униатские храмы и синагога.',
        comment4: 'В 1772 году задвинская часть Друи, которая тогда называлась Придруйск, оказалась в составе Российской империи, а уже в 1793-м – то есть после второго раздела Речи Посполитой – местечко снова объединилось. Так Россия заполучила его целиком.',
        comment5: 'Во время войны с Наполеоном в окрестностях случилось несколько важных боев, а во время Первой мировой Друя стала прифронтовым городом. Тогда здесь появилась узколейка для военных нужд, она соединяла Друю с Браславом и Опсой. В советское время по Западной Двине проходила граница между Беларусской и Латвийской ССР.',
        linkImg: '201.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=0&end=152',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 202,
        name1: '',
        name2: 'КОСТЕЛ И КАТОЛИЧЕСКИЙ МОНАСТЫРЬ',
        name3: '',
        fullName: '',
        slogan: 'КОСТЕЛ СВЯТОЙ ТРОИЦЫ',
        text: 'Высокий костел возвели на деньги Сапеги в 1643-1646 годах, после того как в местечке был основан монастырь бернардинцев. Довольно аскетичный снаружи (что характерно для раннего барокко), внутри он богато усыпан роскошным декором.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '202.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=433&end=736',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 203,
        name1: '',
        name2: 'ПРАВОСЛАВНЫЙ ХРАМ',
        name3: '',
        fullName: '',
        slogan: 'БЛАГОВЕЩЕНСКАЯ ЦЕРКОВЬ',
        text: 'Благовещенская церковь была главной православной святыней Друи, ее строили в XVIII-XIX веках. Судьба у нее довольно типичная для местного храма: в советское время здесь разместили склад, а потом вообще закрыли. Сейчас церковь восстанавливают: башню-колокольню уже отреставрировали.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '203.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=253&end=432',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 204,
        name1: '',
        name2: 'АРХЕОЛОГИЧЕСКИЕ ПАМЯТНИКИ',
        name3: '',
        fullName: '',
        slogan: 'БОРИСОВ КАМЕНЬ',
        text: 'В Беларуси существует группа уникальных памятников прошлого, так называемые Борисовы камни. Они представляют собой огромные (до нескольких метров) валуны с выбитыми на них крестами и различными надписями. Свое название эти камни получили от полоцкого князя Бориса, правившего в XII веке.',
        comment1: 'В Беларуси существует группа уникальных памятников прошлого, так называемые Борисовы камни. Они представляют собой огромные (до нескольких метров) валуны с выбитыми на них крестами и различными надписями. Свое название эти камни получили от полоцкого князя Бориса, правившего в XII веке, имя которого было выбито на большинстве из них. Правда, существовал один Рогволодов камень и еще сохранился безымянный валун известный как Воротишин крест, но все они относятся к тому же времени. Точная причина появления на наших землях столь необычных памятников до сих пор неизвестна.',
        comment2: 'Большинство из Борисовых камней было уничтожено в период борьбы с религией. Та же участь постигла и Рогволодов камень с надписью "Въ 6679 (1171) месяца мая въ 7 день доспенъ крест сей. Господи помози рабу своему Василію въ крещеніи именемъ Рогволоду сыну Борисову", стоявший у деревни Дятлово Оршанского района. Он был варварски взорван в 1930-х гг. и обломки его были использованы для строительства шоссе Москва—Минск.',
        comment3: 'Сохранилось всего четыре "белорусских" Борисовых камня, один из которых в 1888 г. был вывезен в Россию и сейчас находится в музее-заповеднике Коломенское. В Беларуси осталось три: возле храма св. Софии в Полоцке (Витебская обл.), Воротишин крест в д. Камено (Вилейский район, Минская обл.) и в г. Друя (Браславский район, Витебская обл.). Кстати, последний из них долгое время был скрыт под водой на глубине, куда его стянул весенний ледоход еще в первой половине XX века. Только в 2002 году его удалось обнаружить и вытащить на берег.',
        comment4: '',
        comment5: '',
        linkImg: '204.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=35&end=152',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 205,
        name1: '',
        name2: 'ЕВРЕЙСКОЕ НАСЛЕДИЕ БЕЛАРУСИ',
        name3: '',
        fullName: '',
        slogan: 'ЕВРЕЙСКОЕ КЛАДБИЩЕ И МЕМОРИАЛЫ',
        text: 'Во время Второй мировой войны в Друе было создано еврейское гетто, в котором было уничтожено больше двух тысяч человек. На еврейском кладбище, самом большом на территории Беларуси, есть захоронения и жертв холокоста, и могилы с многовековой историей.',
        comment1: 'Из истории мы знаем, что по территории Беларуси проходила черта оседлости, во многих городах и местечках евреи составляли большинство населения. Местечко считалось одним из трех кагалов на Браславщине — два других были в Браславе и Видзах. В конце XIX - начале ХХ веков еврейское население преобладало в большинстве здешних поселений.',
        comment2: 'В 1905 году евреями были 3,5 из 5,5 тыс. жителей Друи. Друйское еврейское кладбище — одно из самых больших и старых не только на Браславщине, но и в Беларуси. Первые захоронения появились здесь предположительно в 1542 году.',
        comment3: 'В начале Второй мировой войны в местечке было создано гетто — друйские евреи подверглись гонениям, затем многих из них расстреляли. Те, кому удалось избежать гибели, навсегда покинули здешние края. В мирное время по инициативе евреев-выходцев из Друи кладбище привели в порядок и окружили каменной оградой. Для Друи это не только место памяти, но и знаковая достопримечательность. Пройдясь по кладбищу, расположенному на пологих холмах среди высоких сосен, можно увидеть уникальные цветные надгробия, которые в Беларуси считаются редкостью.',
        comment4: '',
        comment5: '',
        linkImg: '205.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=95&end=152',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 206,
        name1: 'ДОСТОПРИМЕЧАТЕЛЬНОСТИ',
        name2: '',
        name3: '',
        fullName: '',
        slogan: 'УЗКОКОЛЕЙКА',
        text: 'На рубеже XIX-XX веков в Друе проживало порядка пяти с половиной тысяч человек, работало 275 ремесленников. В городе насчитывалось 359 домов, 52 лавки, 13 культовых строений. Каждый год в поселении проводилось 8 ярмарок. Когда-то из Друи в Дукштас шла узкоколейная дорога.',
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: '',
        comment5: '',
        linkImg: '206.jpg',
        linkVideo: 'https://www.youtube.com/embed/AiYFPFLCuZE?rel=0&start=99&end=152',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
    {
        id: 207,
        name1: '',
        name2: 'ДОСТОПРИМЕЧАТЕЛЬНОСТИ',
        name3: '',
        fullName: '',
        slogan: 'ПОЛЬСКАЯ ШКОЛА',
        text: 'Двадцать лет XX века почти половина территории современной Беларуси находилась в составе другого государства. Гродненская, Брестская, часть Минской и часть Витебской областей по итогам Рижского мирного договора в 1921 году были присоединены ко Второй Речи Посполитой.',
        comment1: 'Двадцать лет XX века почти половина территории современной Беларуси находилась в составе другого государства. Гродненская, Брестская, часть Минской и часть Витебской областей по итогам Рижского мирного договора в 1921 году были присоединены ко Второй Речи Посполитой. ',
        comment2: 'С этого времени и до 1939 года Беларусь была разделена на западную и восточную части. Это разделение не могло не сказаться на архитектуре регионов, ведь на территории Беларуси в то время работали представители двух архитектурных школ двух государств, которые идеологически противостояли друг другу.',
        comment3: 'Западная Беларусь, или, как этот край назвали в Польше, Восточные кресы (kresy - окраины), в составе нового Польского государства считалась отсталым регионом. Тем не менее за два десятилетия поляки вложили в развитие "кресов" миллионы польских злотых. Были восстановлены разрушенные Первой мировой войной города, развивалась инфраструктура края, строились новые предприятия, увеличивалось присутствие польских Вооруженных сил. За эти годы было построено множество зданий разных стилей, большинство из которых сохранились до наших дней.',
        comment4: 'Польская школа в Друе - один из примеров таких типовых построек. Она принимала учеников не только в “польское время”, но еще в семидесятые годы прошлого столетия друевские мальчишки и девченки бегали на занятия по этой лестнице-аллее.',
        comment5: '',
        linkImg: '207.jpg',
        linkVideo: 'https://www.youtube.com/embed/vOeYmsW8t50?rel=0&start=737&end=798',
        coordinates: {
            lat: null,
            lng: null,
        },
    },
];